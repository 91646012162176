Bb = require('backbone')
Backbone.ComputedFields = require('backbone-computedfields')
Ztable = require('ztable')
Hoard = require('backbone.hoard')
Supermodel = require('../../git_modules/backbone.supermodel')
VarModel = require('./VarModel.coffee')
Stats = require('fast-stats').Stats
$ = require('jquery')
_ = require('underscore')
kurtosis = require('compute-kurtosis')
skewness = require('compute-skewness')
PortfolioAnalytics = require('portfolio-analytics')

CacheControl = new Hoard.Control()

API_TOKEN = 'pk_0d622e42cbb045a5a51ead0bd14fb550'


BuildMarketQuoteModel = (tickers) ->
    quote_model = Bb.Model.extend({url: 'https://cloud.iexapis.com/stable/stock/market/batch?symbols='+tickers+'&types=news,quote,chart&range=1y&token='+API_TOKEN, sync:CacheControl.getModelSync()})
    return new quote_model

BuildIndexModel = () ->
    return BuildMarketQuoteModel(['SPY','DIA','QQQ'])

GetTrendingStockTwits = () ->
    fetch('https://crossorigin.me/https://api.stocktwits.com/api/2/trending/symbols/equities.json')
        .then((response) -> response.json())
        .then((data) -> _.pluck(data.symbols, 'symbol'))
        
RiskModel = Bb.Model.extend
    initialize: () ->
        this.set('symbol', this.get('quote').symbol)
        this.set('companyName', this.get('quote').companyName)
        returns = (d['changePercent'] for d in this.get('chart'))
        stats = new Stats().push(returns)
        this.set('std_dev_percent', stats.stddev())
        dayChange = this.get('quote').changePercent
        avgChange = this.get('std_dev_percent')/1.0
        this.set('dayPercentile', Ztable(dayChange/avgChange))

RiskCollection = Bb.Collection.extend
    model: RiskModel
    comparator: (model) ->
        return -Math.abs(model.get('quote').changePercent/model.get('std_dev_percent'))

MarketModelToCollection = (model) ->
    quote_collection = new RiskCollection((x for x in Object.values(model.toJSON())))
    return quote_collection

SectorCollection = Bb.Collection.extend
    url: () ->
        return 'https://cloud.iexapis.com/stable/stock/market/collection/sector?collectionName='+encodeURI(this.name)+'&token='+API_TOKEN
    comparator: (model) ->
        return -model.get('latestVolume')

IEXBatch = Bb.Model.extend
    idAttribute: 'ticker'
    url: ->
        return 'https://cloud.iexapis.com/stable/stock/'+this.attributes.ticker+'/batch?types=relevant,company,quote,news,chart,stats&range=1y&last=10&token='+API_TOKEN
    relations:
        'quote':Bb.Model
        'chart':Bb.Collection
        'company':Bb.Model
        'stats':Bb.Model
    genModel: () ->
        jsonModel = this.toJSON()
        flatJSON = Object.assign(jsonModel, jsonModel['quote'],jsonModel['stats'],jsonModel['company'],jsonModel['chart'])
        returns = (d['changePercent'] for d in jsonModel['chart'])
        prices = (d['close'] for d in jsonModel['chart'])
        stats = new Stats().push(returns)
        flatJSON['std_dev_percent'] = stats.stddev()
        flatJSON['dayPercentile'] = Ztable(flatJSON['changePercent']/(flatJSON['std_dev_percent']/1.0))
        flatJSON['relevant'] = jsonModel['relevant']
        flatJSON['chart'] = jsonModel['chart']
        flatJSON['news'] = jsonModel['news']
        flatJSON['skewness'] = skewness(returns)
        flatJSON['kurtosis'] = kurtosis(returns)
        flatJSON['max_drawdown'] = PortfolioAnalytics.maxDrawdown(prices)
        flatJSON['ulcer_index'] = PortfolioAnalytics.ulcerIndex(prices)
        flatJSON['pain_index'] = PortfolioAnalytics.painIndex(prices)
        flatJSON['gain_to_pain_ratio'] = PortfolioAnalytics.gainToPainRatio(prices)
        combinedModel = new Bb.Model(flatJSON)
        #combinedModel.set('news', new Bb.Collection(jsonModel['news']))
        return combinedModel
    sync: CacheControl.getModelSync()

FrontPageCollection = Bb.Collection.extend
    model: IEXBatch
    comparator: (model) ->
        return -Math.abs(model.get('changePercent'))
    sync: CacheControl.getModelSync()


Gainers = FrontPageCollection.extend
    url: 'https://cloud.iexapis.com/stable/stock/market/list/gainers?token='+API_TOKEN

Losers = FrontPageCollection.extend
    url: 'https://cloud.iexapis.com/stable/stock/market/list/losers?token='+API_TOKEN


MostActives = FrontPageCollection.extend
    url: 'https://cloud.iexapis.com/stable/stock/market/list/mostactive?token='+API_TOKEN

EarningsToday = Bb.Model.extend
    url: 'https://cloud.iexapis.com/stable/stock/market/today-earnings?token='+API_TOKEN

NewsCollection = Bb.Collection.extend
    url: 'https://cloud.iexapis.com/stable/stock/SPY/news?token='+API_TOKEN

PeersCollection = Bb.Collection.extend
    model: IEXBatch

export { GetTrendingStockTwits, BuildMarketQuoteModel, MarketModelToCollection, BuildIndexModel, NewsCollection, SectorCollection, IEXBatch, Gainers, Losers, EarningsToday, MostActives, PeersCollection }
