import _ from 'underscore'
import { SectorCollection, IEXBatch, Gainers, PeersCollection } from './models/IEX.coffee'
import { BuildIndexCollection } from './models/IEX.coffee'
import * as Sentry from '@sentry/browser'

 
Backbone = require('backbone')

analytics = require('universal-ga')
analytics.initialize('UA-127509026-1', {debug: false})

QuoteRouter = Backbone.Router.extend
    initialize: ->
        Sentry.init
            dsn: 'https://38e63f70ca424745aa3fae8b24e538bc@sentry.io/1390762'
            attachStacktrace: 'yes'
        this.bind 'route', this.pageView
        document.getElementsByClassName('pageloader')[0].classList.remove('is-active')
    pageView: ->
        document.getElementsByClassName('pageloader')[0].classList.add('is-active')
        path = "/" + Backbone.history.getFragment()
        analytics.pageview(path)
    routes:
        "":                              'showIndex'
        "ticker/:symbol_string(/)":      'showTicker'
        "t/:symbol_string(/)":           'showTicker'
        "sector/:sector_name(/)":        'showSector'
        "beta-signup(/)":                   'betaSignUp'
        "watchlist(/)":                  'showWatchList'
        "about-var(/)":                     'aboutVar'
        "*notfound":                     'show404'
    aboutVar: () ->
        document.title = "About Value-At-Risk and Contextual Quotes"
        require.ensure ['./views/AboutVar/AboutVar.coffee'], (require) ->
            AboutVarView = require('./views/AboutVar/AboutVar.coffee').AboutVarView
            var_view = new AboutVarView({el:'#content'})
            document.getElementsByClassName('pageloader')[0].classList.remove('is-active')
            var_view.render()
    show404: () ->
        document.title = "A Page with that URL Could Not Be Found"
        require.ensure ['./views/Common/404.coffee'], (require) ->
            RouteNotFoundView = require('./views/Common/404.coffee').RouteNotFoundView
            route_not_found_view = new RouteNotFoundView({el:'#content'})
            document.getElementsByClassName('pageloader')[0].classList.remove('is-active')
            route_not_found_view.render()
    betaSignUp: () ->
        document.title = "Sign Up for the DiHtF Beta Test"
        require.ensure ['./views/BetaSignUp/BetaSignUp.coffee'], (require) ->
            BetaSignUpView = require('./views/BetaSignUp/BetaSignUp.coffee').BetaSignUpView
            beta_view = new BetaSignUpView({el:'#content'})
            document.getElementsByClassName('pageloader')[0].classList.remove('is-active')
            beta_view.render()
    showTicker: (symbol_string) ->
        document.title = "Did #{symbol_string.toUpperCase()} Hit The Fan?"
        iex_info = new IEXBatch({'ticker':symbol_string})
        iex_info.fetch
            success: (data) ->
                require.ensure ['./views/TickerDetail/TickerDetail.coffee', './views/Common/VarCard.coffee'], (require)->
                    TickerView = require('./views/TickerDetail/TickerDetail.coffee')
                    ticker_view = new TickerView.Layout({el:'#content', model:data.genModel()})
                    document.getElementsByClassName('pageloader')[0].classList.remove('is-active')
                    ticker_view.render()
            error: (data, response, options) ->
                require.ensure ['./views/ErrorView.coffee'], (require) ->
                    ErrorView = require('./views/ErrorView.coffee')
                    error_view = new ErrorView.Error404({el: '#content', model: new Backbone.Model(symbol: symbol_string)})
                    document.getElementsByClassName('pageloader')[0].classList.remove('is-active')
                    error_view.render()
    showIndex: ->
        document.title = 'Did The Market Hit The Fan?'
        require.ensure(['./views/Index/Index.coffee'], (require) ->
            IndexView = require('./views/Index/Index.coffee').IndexLayoutView
            index_view = new IndexView({el:'#content'})
            index_view.render())
    showWatchList: ->
        console.log "Watchlist clicked."
        document.title = 'Did My Watchlist Hit The Fan?'
        require.ensure(['./views/WatchList/WatchList.coffee'], (require) ->
            WatchListView = require('./views/WatchList/WatchList.coffee').WatchListView
            watchlist = new WatchListView({el: '#content'})
            document.getElementsByClassName('pageloader')[0].classList.remove('is-active')
            watchlist.render())
    showSector: (sector_name) ->
        document.title = "Did #{sector_name} Hit The Fan?"
        require.ensure(['./views/SectorIndex/SectorIndex.coffee'], (require) ->
            SectorIndexView = require('./views/SectorIndex/SectorIndex.coffee')
            sector_index = new SectorIndexView.SectorLayoutView({el: '#content',model: new Backbone.Model({sector_name:sector_name.replace('_',' ')})})
            document.getElementsByClassName('pageloader')[0].classList.remove('is-active')
            sector_index.render())
export { QuoteRouter }
