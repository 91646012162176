Bb = require('backbone')
Backbone.ComputedFields = require('backbone-computedfields')
Numeral = require('numeral')
Ztable = require('ztable')

class VarModel extends Bb.Model
    defaults:
        period: 1,
        format: 'usd',
        confidence:1.65
        ticker: ''
    initialize: ->
        this.computedFields = new Backbone.ComputedFields(this)
    computed:
        probability:
            depends: ['confidence']
            get: (fields) ->
                return Numeral(Ztable(-1*fields.confidence)).format('%0.00')
        oddsRatio:
            depends: ['confidence']
            get: (fields) ->
                return "1:" + Math.round(1/Ztable(-1*fields.confidence))
        explainVar:
            depends: ['symbol','value_at_risk','confidence','format','period','portfolio_value']
            get: (fields) ->
                periodic_var = fields.confidence * fields.value_at_risk * (fields.period**.5)
                explainVar = if fields.format is "usd" then Numeral(periodic_var).format('$0,0.00') else Numeral(periodic_var/fields.portfolio_value).format('0.00%')
                return explainVar

class DollarVarModel extends Bb.Model
    defaults:
        period: 1,
        format: 'usd',
        confidence:1.65
        ticker: ''
        lossValue: '$ 1.0'
        currencySpanClass: ''
        percentSpanClass: 'is-invisible'
    initialize: ->
        this.computedFields = new Backbone.ComputedFields(this)
    computed:
        probability:
            depends: ['format', 'totalValue', 'lossValue', 'value_at_risk','format','period']
            get: (fields) ->
                periodic_var = parseFloat(fields.value_at_risk) * (parseFloat(fields.period)**.5)
                if fields.format is 'percent'
                    console.log fields.lossValue
                    lossValue = parseFloat(fields.lossValue.replace(' %',''))
                    dollarLoss = (lossValue*parseFloat(fields.totalValue))
                else if fields.format is 'usd'
                    lossValue = parseFloat(fields.lossValue.substring(2).replace(',',''))
                    dollarLoss = lossValue
                return Numeral(1.0 - Ztable(dollarLoss/periodic_var)).format('%0.00')
        oddsRatio:
            depends: ['format', 'totalValue', 'lossValue', 'value_at_risk','format','period']
            get: (fields) ->
                periodic_var = parseFloat(fields.value_at_risk) * (parseFloat(fields.period)**.5)
                if fields.format is 'percent'
                    console.log fields.lossValue
                    lossValue = parseFloat(fields.lossValue.replace(' %',''))
                    dollarLoss = (lossValue*parseFloat(fields.totalValue))
                else if fields.format is 'usd'
                    lossValue = parseFloat(fields.lossValue.substring(2).replace(',',''))
                    dollarLoss = lossValue
                return parseInt(Math.round(1/(1.0 - Ztable(dollarLoss/periodic_var))))


export { DollarVarModel, VarModel }
