// Webpack Polyfill Injector
function main() {
    require("./src/app.coffee");
}
var polyfills = function() {
    return [
        /* Promise */ ('Promise' in this) ? 0 : 1,
        /* fetch */ ('fetch' in this) ? 0 : 1
    ];
}.call(window);
if (polyfills.indexOf(1) === -1) {
    main();
} else {
    var js = document.createElement('script');
    js.src = "/polyfills.bundle." + polyfills.join('') + '.js';
    js.onload = main;
    js.onerror = function onError(message) {
        console.error('Could not load the polyfills: ' + message);
    };
    document.head.appendChild(js);
}