store = require('superstore-sync')
window.store = store

class Watchlist
    constructor: () ->
        if store.local.get('db') is undefined
            store.local.set('db', {"watchlist":[]})
    addItem: (symbol) ->
        old_db = this.showTickers()
        old_db.push({"symbol": symbol})
    deleteItem: (symbol) ->
        db = store.local.get('db')
        new_watchlist = db['watchlist'].filter((item) ->
            item.symbol != symbol)
        db['watchlist'] = new_watchlist
        store.local.set('db', db)
    countItems: () ->
        try
            watchlist = store.local.get('db')['watchlist']
            items = _.pluck(watchlist,'symbol')
            if items.length is 0
                return "!"
            else
                return ""
        catch
            return "!"
    listTickers: () ->
        watchlist = _.pluck(store.local.get('db')['watchlist'], 'symbol')
        return watchlist
    showTickers: () ->
        db = store.local.get('db')
        if db.hasOwnProperty('watchlist')
            return db['watchlist']
        else
            store.local.set('db', {"watchlist":[]})
            this.showTickers()
    isInWatchlist: (symbol) ->
        try
            watchlist = _.pluck(store.local.get('db')['watchlist'], 'symbol')
            if symbol in watchlist
                return true
            else
                return false
        catch
            return false
export { Watchlist }
