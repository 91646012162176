Bb = require('backbone')
Deferred = require('simply-deferred')
Deferred.installInto($)
QuoteRouter = require('./Controller.coffee').QuoteRouter
router = new QuoteRouter()

import { Watchlist } from './models/WatchlistLocalStorage.coffee'

watchlist = new Watchlist()

require.ensure ['./views/Common/NavBar.coffee'], (require) ->
    watchlist = new Watchlist()
    NavBar = require('./views/Common/NavBar.coffee').NavBarView
    Footer = require('./views/Common/NavBar.coffee').FooterView
    MobileNavBarView = require('./views/Common/NavBar.coffee').MobileNavBarView
    nav_links = new NavBar({el:'#navbar', model: new Bb.Model({"count":watchlist.countItems()})})
    footer = new Footer({el:'#footer'})
    footer.render()
    nav_links.render()
    mobile_nav = new MobileNavBarView({el:'#mobile_nav'})
    mobile_nav.render()

Bb.history.start({pushState: true})
